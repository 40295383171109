/*
|--------------------------------------------------------------------------
| Store > State
|--------------------------------------------------------------------------
| File that contains the state property for the current Vuex scope.
*/

import { freshForm, freshCancelForm } from '@/modules/helpers/leaveRequests'

export default function () {
  return {
    leaveRequests: {
      summaries: [],
      data: [],
      pagination: {},
      loading: true,
      loadingSummaries: true
    },

    suggestedLeaves: {
      data: [],
      loading: false
    },

    leaveRequestForm: freshForm(),
    cancelLeaveRequestForm: freshCancelForm()
  }
}
