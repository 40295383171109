/*
|--------------------------------------------------------------------------
| Store > Auth > Actions
|--------------------------------------------------------------------------
|
| This file is where the Module's action methods are registered.
|
| Feel free to edit this file if you have action methods you want
| to include.
|
*/

import addCustomerUser from './addCustomerUser'
import addToAuthCollection from './addToAuthCollection'
import blockTranslator from './blockTranslator'
import blockTranslatorForDepartment from './blockTranslatorForDepartment'
import clearAuthCollection from './clearAuthCollection'
import dowloadInsuranceCertificate from './dowloadInsuranceCertificate'
import dowloadWorkingCertificate from './dowloadWorkingCertificate'
import downloadTranslatorSalaryFile from './downloadTranslatorSalaryFile'
import editAuthUserMeta from './editAuthUserMeta'
import addTextTranslationCandidate from './addTextTranslationCandidate'
import editFilesUsers from './editFilesUsers'
import exportGdprCertificate from './exportGdprCertificate'
import generatePasswordSendVia from './generatePasswordSendVia'
import generatePasswordSendViaPublic from './generatePasswordSendViaPublic'
import getUserFeedbacks from './getUserFeedbacks'
import getUserSurveys from './getUserSurveys'
import initAuthCollection from './initAuthCollection'
import initPusher from './initPusher'
import initToken from './initToken'
import initViewportListener from './initViewportListener'
import loadCompanyOpts from './loadCompanyOpts'
import loadCompanyUserOpts from './loadCompanyUserOpts'
import loadCustomerTypeOpts from './loadCustomerTypeOpts'
import loadDepartmentOpts from './loadDepartmentOpts'
import loadDepartmentUserOpts from './loadDepartmentUserOpts'
import loadMunicipalities from './loadMunicipalities'
import loadMunicipalityUserOpts from './loadMunicipalityUserOpts'
import loadTownOpts from './loadTownOpts'
import loadTrainingOpts from './loadTrainingOpts'
import loadTranslatorLevelOpts from './loadTranslatorLevelOpts'
import loadTranslatorOpts from './loadTranslatorOpts'
import loadTranslatorOptsGeneric from './loadTranslatorOptsGeneric'
import loadTranslatorSalaryFiles from './loadTranslatorSalaryFiles'
import loadTranslatorUnavailableTimesGeneric from './loadTranslatorUnavailableTimesGeneric'
import loadUserFiles from './loadUserFiles'
import loadUserSessionLogs from './loadUserSessionLogs'
import loginAs from './loginAs'
import logoutUserSession from './logoutUserSession'
import postUserNotificationPreference from './postUserNotificationPreference'
import readAuthUserFeedbacks from './readAuthUserFeedbacks'
import readAuthUserMeta from './readAuthUserMeta'
import readUserNotificationPreference from './readUserNotificationPreference'
import removeFromAuthCollection from './removeFromAuthCollection'
import requestDataFromVerificationToken from './requestDataFromVerificationToken'
import requestResetPassword from './requestResetPassword'
import requestToken from './requestToken'
import sendVerification from './sendVerification'
import switchAuthUser from './switchAuthUser'
import unblockTranslator from './unblockTranslator'
import unblockTranslatorForDepartment from './unblockTranslatorForDepartment'
import verifyResetPassword from './verifyResetPassword'
import getInvoiceTextTranslationFee from './getInvoiceTextTranslationFee'
import loadUserVideoSettings from './loadUserVideoSettings'
import getTranslatorEmploymentStatus from './getTranslatorEmploymentStatus'

import getSurveys from './getSurveys'
import getSectors from './getSectors'

import requestPasswordLessLoginLink from './requestPasswordLessLoginLink'
import registerTextTranslator from './registerTextTranslator'
import checkCreditWorthiness from './checkCreditWorthiness'
import loadUserSettings from './loadUserSettings.js'
import getUserTwoFactorSetting from './getUserTwoFactorSetting'
import enableTwoFactor from './enableTwoFactor'
import disableTwoFactor from './disableTwoFactor'
import verifyTwoFactor from './verifyTwoFactor'

export default {
  addCustomerUser,
  addToAuthCollection,
  blockTranslator,
  blockTranslatorForDepartment,
  clearAuthCollection,
  dowloadInsuranceCertificate,
  dowloadWorkingCertificate,
  downloadTranslatorSalaryFile,
  editAuthUserMeta,
  editFilesUsers,
  exportGdprCertificate,
  generatePasswordSendVia,
  generatePasswordSendViaPublic,
  getUserFeedbacks,
  getUserSurveys,
  initAuthCollection,
  initPusher,
  initToken,
  initViewportListener,
  loadCompanyOpts,
  loadCompanyUserOpts,
  loadCustomerTypeOpts,
  loadDepartmentOpts,
  loadDepartmentUserOpts,
  loadMunicipalities,
  loadMunicipalityUserOpts,
  loadTownOpts,
  loadTrainingOpts,
  loadTranslatorLevelOpts,
  loadTranslatorOpts,
  loadTranslatorOptsGeneric,
  loadTranslatorSalaryFiles,
  loadTranslatorUnavailableTimesGeneric,
  loadUserFiles,
  loadUserSessionLogs,
  loginAs,
  logoutUserSession,
  postUserNotificationPreference,
  readAuthUserFeedbacks,
  readAuthUserMeta,
  readUserNotificationPreference,
  removeFromAuthCollection,
  requestDataFromVerificationToken,
  requestResetPassword,
  requestToken,
  sendVerification,
  switchAuthUser,
  unblockTranslator,
  unblockTranslatorForDepartment,
  verifyResetPassword,
  getInvoiceTextTranslationFee,
  loadUserVideoSettings,

  getSurveys,
  getTranslatorEmploymentStatus,
  requestPasswordLessLoginLink,
  getSectors,

  registerTextTranslator,
  addTextTranslationCandidate,
  checkCreditWorthiness,
  loadUserSettings,
  getUserTwoFactorSetting,
  enableTwoFactor,
  verifyTwoFactor,
  disableTwoFactor
}
