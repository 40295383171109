export const statuses = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  CANCELLED: 3
}

export const freshForm = () => ({
  show_popup: false,
  loading: false,
  leave_type_id: null,
  dates: [],
  reason: null,
  suggestion: false,

  conflictedBookingIds: [],

  get from() {
    return (this.dates || [])[0] || null
  },
  get to() {
    return (this.dates || [])[1] || null
  }
})

export const freshCancelForm = () => ({
  loading: false,
  leave_request_id: null,
  status_reason: null
})

export default {
  statuses,
  freshForm,
  freshCancelForm
}
