import { AUTH_API_BASE } from '@/modules/constants/api.js'
import { reach } from '@/modules/helpers/vuexActions.js'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance.js'

export default function (context, payload) {
  const method = 'POST'
  let url = `${AUTH_API_BASE}/api/v1/verify-2fa`
  let options = {
    data: payload,
    is_data_raw: true
  }
  return reach(this, context, url, method, options, axiosInstance)
}
