/*
|--------------------------------------------------------------------------
| Store > Actions
|--------------------------------------------------------------------------
| File that contains the actions property for the current Vuex scope.
*/

import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'
import i18n from '@/locales/i18nClient.js'
import { statuses } from '@/modules/helpers/leaveRequests'
import { showSmallNotif } from '@/modules/helpers/notifications'

const { t: $t } = i18n.global

export default {
  fetchLeaveRequests(context, params = {}) {
    context.commit('setLeaveRequestListLoading', true)

    let url = API.LEAVE_REQUESTS

    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, { params })
        .then((r) => {
          context.commit('setLeaveRequestListData', r.data.data.leave_requests)
          context.commit('setLeaveRequestListPagination', r.data.meta.pagination)

          resolve(r)
        })
        .catch((e) => reject(e))
        .finally(() => context.commit('setLeaveRequestListLoading', false))
    })
  },

  fetchLeaveSummaries(context, params = {}) {
    context.commit('setLeaveRequestSummariesLoading', true)

    let url = `${API.LEAVE_REQUESTS}/summaries`

    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, { params })
        .then((r) => {
          context.commit('setLeaveRequestSummariesData', r.data.data.summaries)

          resolve(r)
        })
        .catch((e) => reject(e))
        .finally(() => context.commit('setLeaveRequestSummariesLoading', false))
    })
  },

  fetchConflictedBookingIds(context) {
    context.commit('setLeaveRequestLoading', true)

    let url = `${API.LEAVE_REQUESTS}/conflicted-bookings`

    let { from, to } = context.state.leaveRequestForm

    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, { from, to })
        .then((r) => {
          context.commit('setConflictedBookingIds', r.data.data.conflicted_bookings)

          resolve(r)
        })
        .catch((e) => reject(e))
        .finally(() => context.commit('setLeaveRequestLoading', false))
    })
  },

  fetchSuggestedLeaves(context, params = {}) {
    context.commit('setSuggestedLeavesLoading', true)

    let url = API.LEAVE_SUGGESTIONS

    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, { params })
        .then((r) => {
          context.commit('setSuggestedLeavesData', r.data.data.leave_suggestions)

          resolve(r)
        })
        .catch((e) => reject(e))
        .finally(() => context.commit('setSuggestedLeavesLoading', false))
    })
  },

  cancelLeaveRequest(context) {
    let data = context.state.cancelLeaveRequestForm

    let url = `${API.LEAVE_REQUESTS}/${data.leave_request_id}/status`

    context.commit('setCancelLeaveRequestLoading', true)

    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, {
          status: statuses.CANCELLED,
          status_reason: data.status_reason
        })
        .then((r) => {
          context.commit('resetCancelLeaveRequestForm')

          resolve(r)
        })
        .catch((e) => {
          let message =
            e.response?.data?.message || $t('something_went_wrong') + '. ' + $t('please_contact_your_administrator')

          showSmallNotif('danger', {
            title: $t('error'),
            message,
            duration: 3000
          })

          reject(e)
        })
        .finally(() => context.commit('setCancelLeaveRequestLoading', false))
    })
  },

  createLeaveRequest(context) {
    let data = context.state.leaveRequestForm

    data.locale = i18n.locale

    let url = API.LEAVE_REQUESTS

    context.commit('setLeaveRequestLoading', true)

    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, data)
        .then((r) => {
          context.commit('resetLeaveRequestForm')

          resolve(r)
        })
        .catch((e) => {
          let message =
            e.response?.data?.message || $t('something_went_wrong') + '. ' + $t('please_contact_your_administrator')

          showSmallNotif('danger', {
            title: $t('error'),
            message,
            duration: 3000
          })
          reject(e)
        })
        .finally(() => context.commit('setLeaveRequestLoading', false))
    })
  }
}
